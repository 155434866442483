<script>
import { loginSuccess } from '@/utils/wx'
import { mapActions } from 'vuex'

export default {
  name: 'login',
  data() {
    return {}
  },
  async created() {
    const { wxcode, toUrl } = this.$route.query
    if (wxcode) {
      await this.GetToken(window.btoa(decodeURIComponent(wxcode))).then((res) => {
        loginSuccess(res).then((e) => {
          this.$router.push(toUrl)
        })
      })
    }
  },
  methods: { ...mapActions(['GetToken']) },
  render() {
    return <div class="login-main"></div>
  },
}
</script>
<style lang="less" scoped>
