import { getAction } from '@/command/netTool'
import { message } from 'ant-design-vue'

export const loginSuccess = (userData) => {
  return new Promise((resolve, reject) => {
    let { user_info, access_token, token_type } = userData
    localStorage.setItem('userInfos', JSON.stringify(user_info))
    getAction('/farmTenant/myList').then((res) => {
      if (res.code == 200) {
        let arr = res.data.filter((s) => s)
        if (arr.length > 0) {
          localStorage.setItem('tenantArr', JSON.stringify(arr))
          localStorage.setItem('appCode', arr[0]['tenantId'])
          localStorage.setItem('logo', arr[0].sysIcon || '')
          resolve()
        } else {
          message.error('没有租户不可登入！')
          setTimeout(() => {
            // window.close()
          }, 3000)
          return
        }
      } else {
        message.error(res.msg)
        setTimeout(() => {
          window.close()
        }, 3000)
      }
    })
  })
}
