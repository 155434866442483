<script>
import { Input, Button } from 'ant-design-vue'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { getAction } from '@/command/netTool'
import { setCookie } from '@/utils/cookie'
import { Modal } from 'ant-design-vue'
import { delCookie } from '@/utils/cookie'
import moment from 'moment'

export default {
  name: 'login',
  data() {
    return {
      currentUser: {},
    }
  },
  created() {
    let data = localStorage.getItem('userInfos')
    if (data) {
      data = JSON.parse(data)
      this.currentUser = {
        name: data.realName ? data.realName : '管理员',
        avatar: data.avatar
          ? data.avatar
          : 'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png',
      }
    } else {
      delCookie('access_token')
      this.$router.push({ name: 'loginDYZ' })
    }
  },
  mounted() {},
  methods: {
    handleLogout(e) {
      Modal.confirm({
        title: '您确定要退出吗？',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          delCookie('access_token')
          this.$router.push({ name: 'loginDYZ' })
        },
        onCancel() {},
      })
    },
    getWeel() {
      const week = moment().day()
      switch (week) {
        case 1:
          return '星期一'
        case 2:
          return '星期二'
        case 3:
          return '星期三'
        case 4:
          return '星期四'
        case 5:
          return '星期五'
        case 6:
          return '星期六'
        case 0:
          return '星期日'
      }
    },
    renderHeader() {
      return (
        <div class="nav-title">
          <div>
            {moment().format('YYYY/MM/DD HH:mm')} {this.getWeel()}
          </div>
          <div>
            您好，{this.currentUser.name}，欢迎来到大堰镇农文旅数字平台！<span onClick={this.handleLogout}>退出</span>
          </div>
        </div>
      )
    },
    renderNav() {
      const config = [
        {
          type: 'jsc',
          onClick: () => {
            const token = localStorage.getItem('Access-Token')?.replace(/"/g, '') || ''
            if (location.origin.includes('10.10.120')) {
              window.open('http://10.10.120.11:3000/dyzbi/#/?token=' + token)
            } else {
              window.open('/dyzbi/#/')
            }
          },
        },
        {
          type: 'pc',
          onClick: () => {
            window.open('/')
          },
        },
        {
          type: 'tc',
          onClick: () => {
            this.$message.info('暂未开放！')
          },
        },
      ]
      return (
        <div class="content">
          {config.map((item) => (
            <div class={item.type} onClick={item.onClick}></div>
          ))}
        </div>
      )
    },
  },
  render() {
    return (
      <div class="nav">
        {this.renderHeader()}
        {this.renderNav()}
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.nav {
  background-image: url('../../assets/View/navDYZ/nav-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.nav-title {
  background-image: url('../../assets/View/navDYZ/title-bg.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: 21px;
  height: 79px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 70px 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
  color: #333333;

  span {
    cursor: pointer;
    margin-right: 2px;
    padding-left: 30px;
    font-size: 16px;
    color: #25615e;
  }
}
.content {
  width: 60%;
  display: flex;
  justify-content: space-around;
  position: fixed;
  left: 0;
  right: 0;
  top: 350px;
  margin: 0 auto;

  > div {
    width: 154px;
    height: 211px;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .jsc {
    background-image: url('../../assets/View/navDYZ/nav-jsc.png');

    &:hover {
      background-image: url('../../assets/View/navDYZ/nav-jsc-on.png');
    }
  }
  .pc {
    background-image: url('../../assets/View/navDYZ/nav-pc.png');

    &:hover {
      background-image: url('../../assets/View/navDYZ/nav-pc-on.png');
    }
  }
  .tc {
    background-image: url('../../assets/View/navDYZ/nav-tc.png');

    &:hover {
      background-image: url('../../assets/View/navDYZ/nav-tc-on.png');
    }
  }
}
</style>
