<script>
import { Alert, Card, Button } from 'ant-design-vue'
import FormView from '@/components/FormView'
import { getAction, putAction } from '@/command/netTool'

export default {
  name: 'personalsettingDetail',
  data() {
    return {
      detail: {},
      queryPwdInfo: {
        initPwdState: '0',
        lastModifyday: '0'
      },
      gradeC: {
        1: '#f8b3a1',
        2: '#ffad34',
        3: '#ffd699',
        4: '#a3e5c4'
      },
      gradeT: {
        1: '密码太弱啦！',
        2: '密码比较弱哦！',
        3: '密码安全！',
        4: '密码很强很安全！'
      },
      colorC: '',
      colorT: ''
    }
  },
  mounted() {
    getAction('/admin/my/queryPwdInfo', {}, '/bpi').then(r => {
      this.queryPwdInfo = r.data
    })
  },
  methods: {
    setCT(data) {
      getAction('/admin/my/queryPwdGrade?password=' + data, {}, '/bpi').then(res => {
        const { level } = res.data
        this.colorC = this.gradeC[level]
        this.colorT = this.gradeT[level]
      })
    },
    getForm1() {
      return {
        type: 'cardForm',
        data: [
          {
            name: '旧密码',
            type: 'input',
            key: 'oldPassword'
          },
          {
            name: '新密码',
            type: 'input',
            key: 'newPassword',
            props: {
              type: 'password',
              suffix: this.colorC && this.colorT
            },
            styles: {
              border: this.colorC && `1px solid ${this.colorC}`
            },
            onChange: data => {
              this.setCT(data)
            }
          },
          {
            value: '建议：长度不小于8位，且包含，大写英文字母、小写英文字母、数字和符号。',
            type: 'text',
            styles: {
              color: '#ff9901',
              fontSize: '14px',
              fontWeight: '400'
            }
          },
          {
            name: '确认密码',
            type: 'input',
            key: 'confirmPassword',
            props: {
              type: 'password'
            }
          }
        ]
      }
    },
    onSubmit() {
      putAction('/admin/my/updatePwd', this.detail, '/bpi').then(res => {
        if (res.code == 200) {
          this.$message.success('修改成功，请重新登录')
          this.onLogout()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    onLogout() {
      this.$store.dispatch('Logout').then(() => {
        this.$router.push({ name: 'login' })
      })
    }
  },
  render() {
    return (
      <Card title="修改密码" class="alert-box">
        {this.queryPwdInfo.initPwdState == '1' && (
          <Alert message="初始密码未修改，请修改初始密码" type="info" showIcon />
        )}
        {this.queryPwdInfo.initPwdState == '0' && (
          <Alert
            message={`已经有 ${this.queryPwdInfo.lastModifyday} 天未修改密码了，请修改密码`}
            type="info"
            showIcon
          />
        )}
        <FormView class="form-box" ref={'_form'} cols={24} data={this.getForm1().data} form={this.detail} />
        <div class="foot-btn-box">
          <Button style="margin-right: 15px" onClick={() => this.onLogout()}>
            取消
          </Button>
          <Button type="primary" onClick={() => this.onSubmit()}>
            确认修改
          </Button>
        </div>
      </Card>
    )
  }
}
</script>
<style lang="less" scoped>
.form-box {
  width: 50%;
  margin: 25px auto;
}
.foot-btn-box {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  padding: 15px 24px;
}
.alert-box {
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  position: relative;
}
</style>
